#loadingBox{
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loading-inner{
  position: relative;
  width: 90px;
  height: 90px;
  text-align: center;
  display: inline-block;
}

.loading-title{
  text-align: center;
}

@keyframes bsload-animate{50%{transform:rotate(360deg) scale(.8)}}
.bsload{border-radius:50% 50% 50% 50%;position:absolute;border-top:2px solid #5cadff;border-left:2px solid #5cadff;border-bottom:2px solid transparent;border-right:2px solid transparent;animation:bsload-animate 2s infinite}
.bsload.bsload1{left:0;top:0;width:90px;height:90px;animation-delay:.4s;border-top:2px solid #2d8cf0;border-left:2px solid #2d8cf0}
.bsload.bsload2{left:10px;top:10px;width:70px;height:70px;animation-delay:.2s;border-top:2px solid #0bb27a;border-left:2px solid #0bb27a}
.bsload.bsload3{left:20px;top:20px;width:50px;height:50px;border-top:2px solid #f90;border-left:2px solid #f90}
