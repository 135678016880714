@font-face {
    font-family: "wq-font"; /* Project id 4249806 */
    src: url('//at.alicdn.com/t/c/font_4249806_gffl0k8jcrc.woff2?t=1696257860701') format('woff2'),
    url('//at.alicdn.com/t/c/font_4249806_gffl0k8jcrc.woff?t=1696257860701') format('woff'),
    url('//at.alicdn.com/t/c/font_4249806_gffl0k8jcrc.ttf?t=1696257860701') format('truetype');
}

.wq-font {
    font-family: "wq-font" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.wq-chanpin:before {
    content: "\e6c7";
}

.wq-hangye:before {
    content: "\e61e";
}

.wq-yingxiao:before {
    content: "\e64c";
}

.wq-finance:before {
    content: "\e70e";
}

.wq-agent:before {
    content: "\e60f";
}

.wq-dataset:before {
    content: "\e6ed";
}

.wq-setting:before {
    content: "\e68f";
}

.wq-sieve:before {
    content: "\e6b6";
}

.wq-dashboard:before {
    content: "\e692";
}

.wq-api:before {
    content: "\e64e";
}

.wq-customer:before {
    content: "\e6c0";
}
