@import "./icon.css";
@import "./antd-custom.css";

/* css reset **/
html,body,h1,h2,h3,h4,h5,h6,div,dl,dt,dd,ul,ol,li,p,blockquote,pre,hr,figure,table,caption,th,td,form,fieldset,legend,input,button,textarea,menu {margin:0;padding:0;}
*,:after,:before {box-sizing: border-box;border-style: solid;border-width: 0}
:focus {outline: none}
body{
    font-family: PingFang SC, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, apple color emoji, segoe ui emoji, Segoe UI Symbol, noto color emoji;
}
html, body, #root{height: 100%; background: #f5f6fa}
table {border-collapse:collapse;border-spacing:0;}
caption,th {text-align:left;font-weight:normal;}
html,body,fieldset,img,iframe,abbr {border:0;}
i,cite,em,var,address,dfn {font-style:normal;}
ul,ol,li {list-style:none;}
a {text-decoration:none; color: #099dfd; font-size: 12px}
a,button {cursor:pointer;}
p{margin-bottom: 0!important;}
img {border:0}
input,textarea {border:none;outline:none;-webkit-appearance:none;-webkit-tap-highlight-color:rgba(0,0,0,0);}
textarea {overflow:auto;resize:none;}
.fl {float:left;}
.fr {float:right;}
.clearfix {zoom:1;}
.clearfix:after {content:'';display:block;clear:both;visibility:hidden;overflow:hidden;}
.flex {display:-webkit-box;display:-webkit-flex;display:flex;}
.flex_item {-webkit-box-flex:1;-webkit-flex:1;flex:1;}
.ellipsis {white-space:nowrap;text-overflow:ellipsis;overflow:hidden}
.ellipsis_2 {display:-webkit-box !important;overflow:hidden;text-overflow:ellipsis;word-break:break-all;-webkit-box-orient:vertical;-webkit-line-clamp:2;}
.show {display:block;}
.hide {display:none;}
.center {text-align:center;}

.clearfix:before,
.clearfix:after{
    content:"";
    display:table;
    clear:both;
}

.transparent{
    background: transparent !important;
    padding: 0 !important;
}

/**常用**/
.gray999{
    color: #999
}
.flex1{
    flex: 1;
}
.w10{
    width: 10px;
}
.mt5{
    margin-top: 5px;
}
.mt10{
    margin-top: 10px;
}
.mr5{
    margin-right: 5px;
}
.mr10{
    margin-right: 10px;
}
.ml5{
    margin-left: 5px;
}
.ml10{
    margin-left: 10px;
}
.mb5{
    margin-bottom: 5px;
}
.mb10{
    margin-bottom: 10px !important;
}
.mb20{
    margin-bottom: 20px !important;
}
.mr20{
    margin-right: 20px;
}
.ml20{
    margin-left: 20px;
}

.flex-row{
    display: flex;
    flex-direction: row;
}
.flex-wrap{
    flex-wrap: wrap;
}
.space-between{
    justify-content: space-between;
}
.flex-row-horizontal-center{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.flex-row-center{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.flex-col-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.full-height{
    height: 100%;
}

.input-text{
    width: 200px;
}
.input-number{
    width: 100px;
}

/**商品创建**/
.productCreateFooter{
    width: calc(100% - 227px);
    right: 40px;
    z-index: 1000;
    background-color: #fefefe;
}
.createFooterWithSecondNav{
    width: calc(100% - 377px) !important;
}
.productCreateFooter .ant-pro-footer-bar-left{
    display: none;
}
.productCreateFooter .ant-pro-footer-bar-right{
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    flex-direction: row;
}
.formWrap{
    margin-bottom: 100px;
}

.warning-text{
    color:  #f60;
}
.space-text{
    margin: 0 5px;
}
.space-right-text{
    margin-right: 5px;
}
.space-left-text{
    margin-left: 5px;
}

.display-none{
    display: none;
}
.fill-in{
    width: 100%;
    height: 100%;
}

.page-loading-spin{
   display: flex;
   width: 100vw;
   height: 100vh;
   flex-direction: column;
   align-items: center;
   justify-content: center
}
.flex-row-only{
    display: flex;
    flex-direction: row;
}
.flex-row{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.flex-col{
    display: flex;
    flex-direction: column;
}
.space-between{
    justify-content: space-between;
}

.form-group-title{
    background-color: #f9f9f9;
    color: #121212;
    font-size: 15px;
    font-weight: 700;
    height: 35px;
    line-height: 35px;
    width: 90%;
    padding-left: 15px;
    margin: 10px auto;
}

.common-upload-btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    color: #999;
    margin: 5px 10px 5px 0;
    border: none;
    box-shadow: 0 0 4px 0 rgba(10,42,97,.2);
    cursor: pointer
}
.common-product-picker-list{
    width: 100%;
}
.common-product-picker-item{
    position: relative;
    margin-right : 10px;
    margin-bottom : 10px;
    float: left;
}
.common-product-picker-delete{
    position: absolute;
    right: -5px;
    top: -5px;
}
.important{
    font-weight: bold;
    color: #f60 !important;
}

.activity-item-progress{
    margin-bottom: 0 !important;
}
.searchForm{
    background-color: #fafafa !important;
    border: 1px solid #f9f9f9;
    border-radius: 4px !important;
    overflow: hidden;
    padding: 12px !important;
}
.cardBox{
    background-color: #fff;
    padding: 16px;
    border-radius: 8px;
    flex: 1;
}
.disable-input{
    background-color: #f9f9f9 !important;
}

/*滚动条*/
::-webkit-scrollbar-track-piece {
    background-color: #fff;
}

::-webkit-scrollbar {
    width: 13px;
    height: 13px;
}

::-webkit-scrollbar-thumb {
    background-color: #aaa;
    background-clip: padding-box;
    min-height: 28px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

 .sidebar::-webkit-scrollbar {
      width: 0;
 }
 .empty-cell{
     position: relative;
     width: 100%;
     padding: 0 !important;
     height: 15px;
 }
 .empty-cell::before{
     position: absolute;
     left: -1px;
     bottom: 0;
     width: 1px;
     top: 0;
     background-color: white;
     content: "";
     height: 100%;
 }
.empty-cell::after{
    position: absolute;
    right: -1px;
    bottom: 0;
    width: 1px;
    top: 0;
    background-color: white;
    content: "";
    height: 100%;
}
